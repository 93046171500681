<template>
  <surround-box
    specialColor="#0c4358"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
    title="荣昌区土壤承载力"
  >
    <div class="content-box">
      <div class="left">
        <div class="row-one" style="margin-top: 15px; margin-bottom: 20px">
          <div class="img-box">
            <!-- <img src="/imgs/pig.png" /> -->
            <div ref="pig" style="width: 100px; height: 100px"></div>
          </div>
          <!-- <span>{{ count }}</span> -->
        </div>
        <!-- <div class="line"></div>
        <div class="line-font">区域存栏数量(头)</div> -->
      </div>
      <div class="right">
        <div class="num" style="margin-top: 15px; margin-bottom: 20px">
          <span class="number-place">0</span>
          <span class="point-place">.</span>
          <span class="number-place">6</span>
          <span class="number-place">2</span>
        </div>
        <div class="iconfont row-1">&#xe6e6;&nbsp;&nbsp;约等于</div>
        <div class="row-2">当季每亩地0.62头猪</div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as SVGA from "svgaplayerweb";
export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.LIVESTOCK_ON_HAND.REPORT).then((res) => {
        this.count = res.count;
      });
    },
    initSvga(dom, url) {
      let player = new SVGA.Player(dom);
      let parser = new SVGA.Parser(dom);
      parser.load(url, (videoItem) => {
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
  },
  mounted() {
    this.getList();
    this.initSvga(this.$refs.pig,'/svga/pigTwo')
  },
};
</script>
<style lang='less' scoped>
.content-box {
  width: 100%;
  height: 230px;
  position: relative;
  display: flex;
  .left {
    flex-grow: 0;
    flex-shrink: 0;
    width: 220px;
    padding: 32px;
    .row-one {
      display: flex;
      align-items: center;
      width: 58px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #36d8ff;
      opacity: 1;
      padding-bottom: 6px;
      .img-box {
        padding-right: 32px;
        margin-left: 42px;
      }
    }
    .line {
      width: 100%;
      height: 12px;
      border-radius: 8px;
      // background: linear-gradient(
      //   180deg,
      //   rgba(186, 255, 125, 0.52) 0%,
      //   rgba(35, 166, 242, 0.38) 100%
      // );
      background: linear-gradient(
        180deg,
        rgba(148, 125, 255, 0.63) 0%,
        rgba(35, 166, 242, 0.33) 100%
      );
      // border: 1px solid #3affa4;
      border: 1px solid #0090ff;
    }
    .line-font {
      width: 100%;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
      opacity: 1;
      text-align: center;
      padding-top: 6px;
    }
  }
  .right {
    padding-top: 30px;
    .num {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .number-place {
        padding: 2px 6px;
        border: 1px solid #36D8FF;
        color: #36d8ff;
        font-size: 26px;
        font-weight: bold;
        margin-left: 8px;
        &:first-child {
          margin-left: 0px;
        }
      }
      .point-place {
        margin-left: 8px;
        color: #36d8ff;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .row-1 {
      color: #36D8FF;
      padding-top: 16px;
    }
    .row-2 {
      color: #36D8FF;
    }
  }
}
</style>